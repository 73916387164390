import Dashboard from './components/Dashboard';
import Signin from './components/Signin';
import Error from './components/Error';
import Flash from './components/Flash';
import SearchView from './components/SearchView';
import UserCreate from './components/UserCreate';
import UserUpdate from './components/UserUpdate';
import UserView from './components/UserView';
import UserPasswordUpdate from './components/UserPasswordUpdate';
import UserUnlockAccount from './components/UserUnlockAccount';
import UserUnlockAccountEnter from './components/UserUnlockAccountEnter';
import UserDelete from './components/UserDelete';
import CollectionCreate from './components/CollectionCreate';
import CollectionUpdate from './components/CollectionUpdate';
import CollectionDelete from './components/CollectionDelete';
import CollectionView from './components/CollectionView';
import CollectionsView from './components/CollectionsView';

import About from './components/static/About';
import WebsitePolicy from './components/static/WebsitePolicy';
import Contact from './components/static/Contact';
import DeveloperKanban from './components/static/DeveloperKanban';
import SupportHaihai from './components/static/SupportHaihai';
import Faq from './components/static/Faq';

class Router {
    constructor(path) {
        this.path = path;
        this.page = <Dashboard />;
        this.pathElems = path.split("/");
        this.pageType = this.pathElems[0];
        this.itemId1 = (this.pathElems[1]) ? this.pathElems[1] : '';
        this.itemId2 = this.pathElems[2];
        this.itemId1merge = this.pathElems.slice(1).join("/");
        this.itemId2merge = this.pathElems.slice(2).join("/");
    }

    pageMap() {
        return {
            "": <Dashboard />,
            "signIn": <Signin />,
            "newUser": <UserCreate />,
            "updateUser": <UserUpdate />,
            "viewUser": <UserView user={this.itemId1} />,
            "passwordUpdate": <UserPasswordUpdate />,
            "unlockAccount": <UserUnlockAccount user={this.itemId1} />,
            "userVerify": <UserUnlockAccountEnter />,
            "flash": <Flash type={this.itemId1} />,
            "deleteAccount": <UserDelete />,
            "newCollection": <CollectionCreate />,
            "updateCollection": <CollectionUpdate collectionId={this.itemId1merge} />,
            "deleteCollection": <CollectionDelete collectionId={this.itemId1merge} />,
            "viewCollection": <CollectionView user={this.itemId1} collectionId={this.itemId2merge} />,
            "viewCollections": <CollectionsView />,
            "searchResults": <SearchView query={this.itemId1} />,
        }
    };

    renderStaticPage(pageType) {
        return {
            "about": <About />,
            "policy": <WebsitePolicy />,
            "contact": <Contact />,
            "developerKanban": <DeveloperKanban />,
            "supportHaihai": <SupportHaihai />,
            "faq": <Faq />,
        }[pageType]
    }

    route() {
        var renderComponent = this.pageMap()[this.pageType];
        if (this.pageType === "info") {
            this.page = this.renderStaticPage(this.itemId1); return;
        } else if (renderComponent === undefined) {
            this.page = <Error type='This endpoint is not available' />;
        } else {
            this.page = renderComponent;
        }
    }
}

export default Router;