import React from 'react';

class URLrow extends React.Component {
    render() {
        let url = this.props.url;
        const name = this.props.name;
        const description = this.props.description;
        url = (!url.startsWith("http")) ? "http://"+url : url;
        return (
            <tr className="table-view-row left-content">
                {(url !== "http://") ? <td className="table-view-cell vert-padding width30"><a href={url} target="_blank" rel="noreferrer">{url}</a></td> : <td className="table-view-cell vert-padding width30"></td>}
                <td className="table-view-cell vert-padding">{name}</td>
                <td className="table-view-cell vert-padding">{description}</td>
            </tr>
        );
    };
}

export default URLrow;