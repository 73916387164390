const host = process.env.REACT_APP_EXPRESS_HOST;
const port = process.env.REACT_APP_EXPRESS_PORT;
const protocol = process.env.REACT_APP_EXPRESS_PROTOCOL;
const endpoint = `${protocol}://${host}:${port}`;

class Search {
    getSearchCollections(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/getSearchCollections`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    getSearchUsers(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/getSearchUsers`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }
}

export default Search;