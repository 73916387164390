import React from 'react';
// import MetaWidget from './widget/MetaWidget';
import LatestCollectionsWidget from './widget/LatestCollectionsWidget';
import LatestContributorsWidget from './widget/LatestContributorsWidget';
import LatestUpdatesWidget from './widget/LatestUpdatesWidget';
import FeaturedItemsWidget from './widget/FeaturedItemsWidget';
import Widget from '../controllers/widget';

const _widget = new Widget();

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			load: false,
		};
	}

	async componentDidMount() {
        const latestCollectionsData = await _widget.getWidgetDataLatestCollections();
		const latestContributorsData = await _widget.getWidgetDataLatestContributors();
		const latestUpdatesData = await _widget.getWidgetDataLatestUpdates();
		const metaData = await _widget.getWidgetDataMeta();
		const featuredItemsData = await _widget.getWidgetFeaturedItems();
        this.setState({
			load: true,
            latestCollectionsData: latestCollectionsData,
			latestContributorsData: latestContributorsData,
			latestUpdatesData: latestUpdatesData,
			metaData: metaData,
			featuredItemsData: featuredItemsData
        });
	}
	/*
		add to widgets later
		<MetaWidget dataObj={this.state.metaData} />
	*/

    render() {
        if (this.state.load) {
			return (
				<div id="widgets">
					<h2 className="introDash">About</h2>
					<p id="intro">haihai.link is a web application to manage links and URLs. Users can create a profile and manage URL collections which can be shared with links or kept private; profiles can also have publicly featured URL sets. Data updates are available on the dashboard in the main page.</p>
					<h2 className="introDash">Dashboard</h2>
					<LatestCollectionsWidget dataObj={this.state.latestCollectionsData} />
					<FeaturedItemsWidget dataObj={this.state.featuredItemsData} />
					<LatestContributorsWidget dataObj={this.state.latestContributorsData} />
					<LatestUpdatesWidget dataObj={this.state.latestUpdatesData} />
				</div>
			);
		}
		return ( <div className="pendingElem"></div> );
    };
};

export default Dashboard;