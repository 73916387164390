import React from 'react';

class SearchItem extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data["data"];
        this.valid = this.props.data["valid"];
    }
    
    render() {
        if (this.props.type === "collection") {
            if (!this.valid) {
                return (
                    <div>
                        <h2>error - <span className="error">[{this.props.data["error"]}]</span></h2>
                    </div>
                )
            }
            if (!this.data || this.data.length === 0) { 
                return (
                    <div>
                        <h1 className="no-margin-bottom">URL Item Results</h1>
                        <p>no URL collections found</p>
                    </div>
                )
            }
            return (
                <div>
                    <h1 className="no-margin-bottom">URL Item Results</h1>
                    <table className="some-margin-bottom">
                        <tr><td className="vert-padding">name</td><td className="left-space-mini side-padding">description</td><td className="left-space-mini side-padding">username</td></tr>
                        { this.data.map((item) => 
                            <tr className="collectionElem left-content" onClick={() => window.location=`/viewCollection/${item.user}/${item.name.replace(/ /g, "_")}`}>
                                <td className="collectionAttribute vert-padding">{item.name}</td>
                                <td className="left-space-mini side-padding">{item.description}</td>
                                <td className="left-space-mini side-padding">{item.user}</td>
                            </tr>
                        )}
                    </table>
                </div>
            );
        }
        return <div></div>;
    };
}

export default SearchItem;