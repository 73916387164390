import React from 'react';

class WebsitePolicy extends React.Component {
	render() {
		return (
			<div class="staticPage">
				<h1>haihai.link website policy</h1>
				<small>updated 2022/07/01</small>
				<div className="some-margin-vertical left-border">
					<h2>website management</h2>
					<p>
						haihai.link is managed by the website administrator (currrently senaonp). Availability of the web application 
						is dependent on the administrator's decisions and general maintenance. In the case of maintenance, updates may cause the 
						website to be unavailable at times such as in implementing new features or fixing bugs. The administrator maintains 
						the ability to take any actions on the website with regards to data, code, updates, maintenance, operations, and any 
						other aspects of this web application.
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>website usage</h2>
					<p>
						haihai.link is free to use. There is no cost to create or use accounts on this web application. This website is also 
						not monetized - any expenses necessary for haihai.link's functioning (cloud hosting, domain registration, compute, 
						database, etc.) are provided by the administrator.
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>user data</h2>
					<p>
						User data is maintained and secured, on a best-effort or availability basis, by the administrator. It is acknowledged 
						the website administrator is not responsible or accountable for any unexpected access, addition, modification, 
						or deletion of data in the application.
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>user content</h2>
					<p>
						User content added to the website is the user's responsibility. Intentional use of website resources to disrupt 
						webservice availability and traffic is prohibited. haihai.link is available for public use and consideration should
						be given for everyone to be able to use the application. 
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>user account management</h2>
					<p>
						This website does not require emails or other personal identifiers for accounts; only a username and password is  
						required to create an account. As a result, accounts cannot be verified in cases such as forgetting passwords, so it 
						is highly recommended for users to securely maintain passwords for any managed accounts.
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>account conventions</h2>
					<p>
						User names, user passwords, and security question answers should only contain alphanumeric characters. There are also 
						character limits on user names and descriptions as well as URL collection names and descriptions which should be noted
						as validations in the web forms.
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>planned maintenance and service outages</h2>
					<p>
						This website may undergo both planned maintenance and unexpected outages; the administrator will, on a best-effort or availability basis, attempt to
						remedy web application availability in cases of unexpected outages. Any planned maintenance for website updates are typically 
						on weekend days around 12am EST though this is subject to change depending on the nature of updates. For unexpected web application outages outside normal
						maintenance times, it is recommended to wait for around 15 minutes and then try to reload the website again; if the outage still persists, feel free to send 
						an email from the <a href="/info/contact/">contact page</a>.
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>policy updates</h2>
					<p>
						This website policy is subject to change and updates by the administrator. 
					</p>
				</div>
			</div>
		);
    };
};

export default WebsitePolicy;