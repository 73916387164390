import React from 'react';

class LatestContributorsWidget extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	async componentDidMount() {
        this.setState({
			load: true
        });
	}

	render() {
		if (this.state.load) {
			return (
				<div className="widget">
					<span>Latest contributors</span><br /><br />
					{this.props.dataObj.data.map((item) => <p className="widgetWithSrc contributorItem" onClick={() => {window.location = "viewUser/"+item.user}}>{item.user}</p> )}
				</div>
			);
		}
		return ( <div className="pendingElem"></div> );
    };
};

export default LatestContributorsWidget;