import React from 'react';

class URL extends React.Component {
    render() {
        let url = this.props.url;
        const name = this.props.name;
        const description = this.props.description;
        url = (!url.startsWith("http")) ? "http://"+url : url;
        return (
            <div className="url">
                {(name) ? <span className="urlName">{name}</span> : null}
                <div className="urlDetail">
                    {(description) ? <div className="urlDescription">{description}</div> : null}
                    {(url !== "http://") ? <div className="urlHyper"><a target="_blank" rel="noreferrer" href={url}>{url}</a></div> : null}
                </div>
            </div>
        );
    };
}

export default URL;