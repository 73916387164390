import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Router from './Router';

function App() {
	const router = new Router(window.location.pathname.slice(1));
	router.route();
	return (
        <div>
			<Navbar />
			<div id="content">{ router.page }</div>
			<Footer />
		</div>
    );
};

export default App;