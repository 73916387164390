import React from 'react';
import User from '../controllers/user';
import Error from './Error';
import CollectionElem from './mini/CollectionElem';

const _user = new User();

class UserView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            status: "",
            collections: [],
        }
    }

    async componentDidMount() {
        if (!this.props.user) {
            this.setState({error: <Error type="user not specified at this endpoint" />});
            return;
        }
		let user = await _user.getUserPublic({"name": this.props.user});
        if (!user["valid"]) { this.setState({error: <Error type={user["error"]} />}); return; }
        this.setState({
            name: user["name"],
            description: user["description"],
            status: user["status"],
            collections: user.collections,
            isValid: true
        });
	}

    getTimeDiff(time) {
        let diff = Math.floor((Date.now()-time)/1000);
        if (!diff) { return 'N/A'; }
        if (diff <= 60) {
            return diff.toString() + "s";
        } else if (diff <= 3600) {
            return Math.floor(diff/60).toString() + "m";
        } else if (diff <= 86400) {
            return Math.floor(diff/3600).toString() + "h";
        } else {
            return Math.floor(diff/86400).toString() + "d";
        }
    }

    getCollectionsElem() {
        if (this.state.collections.length === 0) {
            return <p className="some-margin-vertical">[ it appears this user does not have public URL collections ]</p>;
        } else {
            return <table className="margin-vertical width-full"><tbody>
                { this.state.collections.map((collection) => <CollectionElem name={collection.name} description={collection.description} public={collection.public} username={this.state.name} hideRes />) }
            </tbody></table>;
        }
    }

    getCollectionsItemCount() {
        if (this.state.collections.length === 0) {
            return null;
        } else {
            return <div className="border-top-mini">
                <span className="text-with-box no-wrap inline no-wrap mini-margin-top">number of URL collections: [{this.state.collections.length}]</span>
                { this.state.collections.map((collection) => <a href={`/viewCollection/${this.state.name}/${collection.name.replace(/ /g, "_")}`} className="text-with-box text-with-box-hover left-space-mini inline no-wrap mini-margin-top">{collection.name}: [{collection.urls.length}] | <small> {this.getTimeDiff(collection.updated)}</small></a>) }
            </div>;
        }
    }

    render() {
        if (this.state.error) { return ( this.state.error ) }
        if (this.state.isValid) {
            return (
                <div id="view_user" className="generic-form">
                    <h1 className="viewElem">{this.state.name}</h1>
                    <div className="viewElem">{this.state.description}</div>
                    {this.state.status ? <span className="viewElem text-with-box-plain">{this.state.status}</span> : null}
                    {this.getCollectionsElem()}
                    <br />
                    {this.getCollectionsItemCount()}
                </div>
            );
        }
        return ( <div className="pendingElem"></div> );
    };
};

export default UserView;