import React from 'react';

class LatestUpdatesWidget extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	async componentDidMount() {
        this.setState({
			load: true
        });
	}

	render() {
		if (this.state.load) {
			return (
				<div className="widget">
					<span>Website updates</span>
					<table>
						<thead><tr className="widget-header"><td>date</td><td>item</td></tr></thead>
						<tbody>
							{this.props.dataObj.data.map((item) => <tr><td>{item.date}</td><td>{item.item}</td></tr> )}
						</tbody>
					</table>
				</div>
			);
		}
		return ( <div className="pendingElem"></div> );
    };
};

export default LatestUpdatesWidget;