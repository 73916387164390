import React from 'react';
import Collection from '../controllers/collection';
import Error from './Error';
import URL from './mini/URL';
import URLrow from './mini/URLrow';

const _collection = new Collection();

class CollectionView extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            user: window.localStorage.getItem("user"),
            token: window.localStorage.getItem("token"),
            listView: true,
            listToggle: 'toggle-on',
            tableToggle: 'toggle-off'
        }
	}

    async componentDidMount() {
        let request = {
            token: this.state.token,
            user: this.props.user,
            collectionId: this.props.collectionId,
        }
        let result = await _collection.getCollection(request);
        if (result.valid) {
            this.setState({
                isValid: true,
                collection: result.collection,
                isOwner: result.isOwner,
            });
        } else {
            this.setState({authError: <Error type={result.error} />});
        }
	}

    renderURLs() {
        if (this.state.listView) {
            return (
                <div>
                    { this.state.collection.urls ? 
                        this.state.collection.urls.map((url) => <URL name={url.urlName} description={url.urlDescription} url={url.url} />) :
                        null
                    }
                </div>
            )
        } else {
            return (
                <table>
                    <tr className="table-view-row left-content"><td className="vert-padding width30 table-view-cell">url</td><td className="vert-padding table-view-cell">name</td><td className="vert-padding table-view-cell">description</td></tr>
                    { this.state.collection.urls ? 
                        this.state.collection.urls.map((url) => <URLrow name={url.urlName} description={url.urlDescription} url={url.url} />) :
                        null
                    }
                </table>
            )
        }
    }

    setView(type) {
        if (type === "table") {
            this.setState({
                listToggle: 'toggle-off',
                tableToggle: 'toggle-on',
                listView: false
            });
        } else {
            this.setState({
                listToggle: 'toggle-on',
                tableToggle: 'toggle-off',
                listView: true
            });
        }
    }

    getTimeDiff(time) {
        let diff = Math.floor((Date.now()-time)/1000);
        if (!diff) { return '0s'; }
        if (diff <= 60) {
            return diff.toString() + "s";
        } else if (diff <= 3600) {
            return Math.floor(diff/60).toString() + "m";
        } else if (diff <= 86400) {
            return Math.floor(diff/3600).toString() + "h";
        } else {
            return Math.floor(diff/86400).toString() + "d";
        }
    }

    render() {
        if (this.state.authError) { return ( this.state.authError ) }
        if (this.state.isValid) {
            let timeDiff = this.getTimeDiff(this.state.collection.updated);
            return (
                <div className="generic-form">
                    <div className="float-right data-margin-bottom">
                        <a className="text-with-box text-with-box-hover" href={`/viewUser/${this.props.user}`}>user: {this.props.user}</a>
                        <span className="text-with-box left-space-mini">items: {this.state.collection.urls.length}</span>
                        <span className="text-with-box left-space-mini">updated: {timeDiff}</span>
                    </div>
                    <h1 style={{clear:"both"}}>{this.state.collection.name}</h1>
                    <p>{this.state.collection.description}</p>
                    { this.state.isOwner ? <a href={`/updateCollection/${this.state.collection.name.replace(/ /g, "_")}`}>edit collection</a> : null }
                    <div className="some-margin-top">
                        <button className={`left-button ${this.state.listToggle}`} onClick={() => this.setView("list")}>list view</button>
                        <button className={`right-button ${this.state.tableToggle}`} onClick={() => this.setView("table")}>table view</button>
                    </div>
                    <div className="viewElem bottom-space">{this.renderURLs()}</div><br /><br />
                    { this.state.isOwner ? <a href={`/deleteCollection/${this.state.collection.name.replace(/ /g, "_")}`}>delete collection</a> : null }
                </div>
            );
        }
        return ( <div className="pendingElem"></div> );
    };
};

export default CollectionView;