import React from 'react';

class Flash extends React.Component {
    createUser() {
        return (
            <div style={{textAlign:'center',padding:'50px 0'}}>
                <h1>successfully created user account</h1>
                <br />
                <p>[ <a href='/signIn'>click here to go to the sign-in page</a> ]</p>
                <p className="some-margin-vertical">[ <a href='/'>click here to go to homepage</a> ]</p>
            </div>
        )
    }

    signedOut() {
        return (
            <div style={{textAlign:'center',padding:'50px 0'}}>
                <h1>successfully signed out of account</h1>
                <p>thanks for using haihai.link</p>
                <br />
                <p>[ <a href='/'>click here to go to homepage</a> ]</p>
            </div>
        )
    }

    updatedPassword() {
        return (
            <div style={{textAlign:'center',padding:'50px 0'}}>
                <h1>successfully updated password</h1>
                <br />
                <p>[ <a href='/'>click here to go to homepage</a> ]</p>
                <p className="some-margin-vertical">[ <a href='/viewCollections'>click here to go to URL collections</a> ]</p>
            </div>
        )
    }

    updatedUser() {
        return (
            <div style={{textAlign:'center',padding:'50px 0'}}>
                <h1>successfully updated user</h1>
                <br />
                <p>[ <a href='/'>click here to go to homepage</a> ]</p>
                <p className="some-margin-vertical">[ <a href='/viewCollections'>click here to go to URL collections</a> ]</p>
            </div>
        )
    }

    deletedAccount() {
        return (
            <div style={{textAlign:'center',padding:'50px 0'}}>
                <h1>account has been deleted</h1>
                <br />
                <p>[ <a href='/'>click here to go to homepage</a> ]</p>
            </div>
        )
    }

    deletedCollection() {
        return (
            <div style={{textAlign:'center',padding:'50px 0'}}>
                <h1>URL collection has been deleted</h1>
                <br />
                <p>[ <a href='/'>click here to go to homepage</a> ]</p>
                <p className="some-margin-vertical">[ <a href='/viewCollections'>click here to go to URL collections</a> ]</p>
            </div>
        )
    }

    render() {
        if (this.props.type === "createUser") { return this.createUser(); }
        if (this.props.type === "signedOut") { return this.signedOut(); }
        if (this.props.type === "updatedPassword") { return this.updatedPassword(); }
        if (this.props.type === "updatedUser") { return this.updatedUser(); }
        if (this.props.type === "deletedAccount") { return this.deletedAccount(); }
        if (this.props.type === "deletedCollection") { return this.deletedCollection(); }
    };
};

export default Flash;