import React from 'react';

class Error extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            type: props.type
        };
	}

    render() {
        return (
			<div className="generic-form">
				<h1 className="title">Sorry, it appears there's an error</h1>
                <h3>error type: (<span className='error'>{this.state.type}</span>)</h3>
				<h3>An error typically means that either this endpoint does not exist or<br/>you do not have permissions to view this page's content or are not authenticated to the website</h3>
				<br/>
			</div>
		);
    };
};

export default Error;