import React from 'react';
import Search from '../controllers/search';
import SearchItem from './mini/SearchItem';

const _search = new Search();

class SearchView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collectionsResult: [],
            usersResult: [],
            pending: true,
        }
        this.query = decodeURIComponent(this.props.query.split("_").join(" "));
    }

    async componentDidMount() {
        const collectionsResult = await _search.getSearchCollections({query: this.query});
        this.setState({
            collectionsResult: collectionsResult,
            pending: false,
        })
	}

    render() {
        if (this.state.pending) { return ( 
            <div id="view_user" className="generic-form left-content some-margin-vertical">
                <p>query: <span className="queryItem">{this.query}</span></p>
                <hr />
                searching for items
            </div>
        )}
        return (
            <div id="view_user" className="generic-form left-content some-margin-vertical">
                <p>query: <span className="queryItem">{this.query}</span></p>
                <hr />
                {<SearchItem type="collection" data={this.state.collectionsResult} />}
            </div>
        );
    };
};

export default SearchView;