const host = process.env.REACT_APP_EXPRESS_HOST;
const port = process.env.REACT_APP_EXPRESS_PORT;
const protocol = process.env.REACT_APP_EXPRESS_PROTOCOL;
const endpoint = `${protocol}://${host}:${port}`;

class User {
    createUser(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/createUser`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    getUser(request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/getUser`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    };

    getUserPublic(request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/getUserPublic`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    };

    updateUserToken(request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/updateUserToken`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    };

    updateUser(request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/updateUser`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    updateUserPassword(request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/updateUserPassword`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    deleteUser(request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/deleteUser`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    getIsUserAttributeValueUsed (request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/getIsUserAttributeValueUsed`, {
                method: 'GET', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    getIsSignedIn (request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/getIsSignedIn`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    getUserCount() {
        return new Promise (resolve => {
            fetch(`${endpoint}/getUserCount`).then(res => { resolve(res.json()); });
        });
    };

    getIsUserAndSetupQuestions(request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/getIsUserAndSetupQuestions`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    getUserQuestions(request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/getUserQuestions`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    validateUserAnswersAndTokenize(request) {
        return new Promise (resolve => {
            fetch(`${endpoint}/validateUserAnswersAndTokenize`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }
}

export default User;