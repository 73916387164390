import React from 'react';

class About extends React.Component {
	render() {
		return (
			<div class="staticPage">
				<h1>About</h1>
				<p>
					haihai.link is a web application to manage links and URLs. Users can create a profile and manage URL collections which can be shared with links or kept private; profiles can also have publicly featured URL sets. Data updates are available on the dashboard in the main page.<br /><br /> 
				</p>
				<hr />
				<h1>Ongoing tasks</h1>
				<p>
					Current statuses on items such as features, improvements, and issue fixes for haihai.link are managed here: <a href="http://greentea.moe/data/kanban/haihai/">greentea.moe/data/kanban/haihai/</a><br /><br />
				</p>
				<hr />
				<h1>Technical details</h1>
				<div className="some-margin-vertical left-border">
					<h2>API</h2>
					<p>
						A Python API to access data on haihai.link is available here: <a target="_blank" rel="noreferrer" href="https://github.com/senaonp/haihai.link-API">https://github.com/senaonp/haihai.link-API</a>. 
						The module includes workflows to programmatically access public and account-specific data.
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>Frontend</h2>
					<p>
						I used React and HTML/CSS/JS. Although the webapp appears to have multiple pages, it is actually a single-page web appliction.
						I implemented a custom routing solution to parse out paths to render components, so it looks cleaner and user-friendly. Views are 
						rendered as components and backend calls are handled through programmed modules for each item type.
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>Backend</h2>
					<p>
						I used Express, Node.js, and aws-sdk for backend. Express is used for handling front-end request payloads to the database 
						webservice which is DynamoDB. aws-sdk is used for interfacing with the database client. Express sends response payloads 
						(usually JSON but sometimes raw data) back to the front-end after completing requests.
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>Systems Design</h2>
					<p>
						This web application runs on AWS cloud. The haihai.link domain is managed in DNS with a record which routes to ELB (load 
						balancer). ELB has ACM certificate to enable https and forwards to EC2 servers on frontend and backend endpoints. At the 
						time of this writing, this webapp is provided by two EC2 servers in separate AZ subnets. The EC2 servers use Apache httpd 
						to serve frontend resources and Express for backend server.
					</p>
				</div>
				<div className="some-margin-vertical left-border">
					<h2>Software Development and Systems Operations (DevOps)</h2>
					<p>
						To support continual and stable releases on the website, processes have been setup to ensure that develop, test, 
						build, and deploy stages are consistent. Implementing new updates involves 5 components (local development, S3, EC2, frontend, 
						backend). In the case of updating both frontend and backend, the time to migrate changes to production usually takes five minutes 
						with a linear rollout of hosts.
					</p>
					<ul>
						<li>local development: feature development, code refactoring, bug fixes, etc. are done on localhost using arbitrary ports 
							with a subset of prod-like data for frontend and backend</li>
						<li>S3: once updates are tested and ready to be implemented, new code is uploaded to S3 bucket</li>
						<li>EC2: the webhosts sync with S3 assets to download the latest changes</li>
						<li>frontend: configuration changes are made to switch from development to production; static assets are built; production 
							assets are copied to apache webserver</li>
						<li>backend: configuration changes are made to switch from development to production; node process is restarted</li>
					</ul>
				</div>
				<hr />
				<h1>Team</h1>
				<div className="teamProfile">
					<img alt="website developer senaonp" src="/assets/senaonp.jpg" style={{"border-radius":"50%", "width":"150px", "margin-top":"20px"}}></img>
					<p style={{"font-size":"1.5em","margin":"5px 0px"}}><a href="/viewUser/senaonp">senaonp</a></p>
					<p style={{"font-size":"1.1em","margin":"0px"}}>website developer</p>
					<p>i produced haihai.link as a side-project during free time; personal website is <a href="http://greentea.moe">greentea.moe</a></p>
				</div>
			</div>
		);
    };
};

export default About;