import React from 'react';
import User from '../controllers/user';

const _user = new User();

class UserCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            password: "",
            passwordVerify: "",
            description: "",
            sq1: "",
            sa1: "",
            sq2: "",
            sa2: "",
            verifyPolicy: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleChange(e) {
        if (e.target.type === "checkbox") {
			this.setState({[e.target.name]: e.target.checked});	
		} else {
			this.setState({[e.target.name]: e.target.value});
		}
    };

    async handleSubmit(e) {
        e.preventDefault();
        if (this.isStaticValid()) {
            var result = await _user.createUser(this.state);
            if (result.valid) { window.location = '/flash/createUser'; }
            else { this.setState({"error": result.error}); }
        }
    };

    isStaticValid() {
        var errors = [];
        var regex = /^[a-zA-Z0-9]+$/i;
        (this.state.name.trim() === "") && errors.push("username should not be empty");
        (!this.state.name.match(regex)) && errors.push("username should only contain alphanumeric characters");
        (this.state.password.trim() === "") && errors.push("password should not be empty");
        (!this.state.password.match(regex)) && errors.push("password should only contain alphanumeric characters");
        (this.state.password !== this.state.passwordVerify) && errors.push("password is not verified; please ensure that the password fields match");
        (!this.isSecurityQuestionsValid()) && errors.push("if setting up security questions; please fill out all the security question and answer fields. answers should only contain alphanumeric characters");
        !(this.state.name.length < 50) && errors.push("name should be less than 50 characters");
        !(this.state.description.length < 250) && errors.push("profile description should be less than 250 characters");
        !(this.state.verifyPolicy) && errors.push("please check the box to verify acknowledging the website policy");
        this.setState({"error": errors.join(" | ")});
        return errors.length === 0;
    }

    isSecurityQuestionsValid() {
        var regex = /^[a-zA-Z0-9]+$/i;
        if (this.state.sq1.length > 0 || 
            this.state.sq2.length > 0 || 
            this.state.sa1.length > 0 || 
            this.state.sa2.length > 0) {
                if (this.state.sq1.trim().length === 0 || 
                    this.state.sq2.trim().length === 0 || 
                    this.state.sa1.trim().length === 0 || 
                    this.state.sa2.trim().length === 0 ||
                    !this.state.sa1.match(regex) ||
                    !this.state.sa2.match(regex)) {
                        return false;
                }
        }
        return true;
    }

    render() {
        return (
            <div id="create_user" className="generic-form">
                <form className="generic-form" onSubmit={this.handleSubmit}>
                    <h1 className="form-header">Create An Account</h1>
                    <div className="field dist"><label>username: </label><input name="name" type="text" value={this.state.username} onChange={this.handleChange}></input></div>
                    <div className="field dist"><label>password: </label><input name="password" type="password" value={this.state.password} onChange={this.handleChange}></input></div>
                    <div className="field dist"><label>re-enter password: </label><input name="passwordVerify" type="password" value={this.state.passwordVerify} onChange={this.handleChange}></input></div>
                    <hr />
                    <div className="field dist"><label>profile description (optional): </label><textarea name="description" value={this.state.description} onChange={this.handleChange}></textarea></div>

                    <div id="security-questions">
                        <small className="form-text large-dist">setting up security questions is only required if you'd like to setup password reset; <br/><br/><span className="important-text">forgotten passwords can only be reset using security questions</span></small>
                        <div className="field dist"><label>security question 1: </label><input name="sq1" type="text" value={this.state.secQ1} onChange={this.handleChange}></input></div>
                        <div className="field dist"><label>answer to security question 1: </label><input name="sa1" type="text" value={this.state.secA1} onChange={this.handleChange}></input></div>
                        <div className="field dist section-header"><label>security question 2: </label><input name="sq2" type="text" value={this.state.secQ2} onChange={this.handleChange}></input></div>
                        <div className="field dist"><label>answer to security question 2: </label><input name="sa2" type="text" value={this.state.secA2} onChange={this.handleChange}></input></div>
                    </div>
                    
                    <div className="dist">check the checkbox to acknowledge the <a rel="noreferrer" target="_blank" href="https://haihai.link/info/policy/">website policy</a>:
                        <input name="verifyPolicy" className="left-space-mini" type="checkbox" onChange={this.handleChange}></input>
                    </div>
                    <div className="submit-input"><input type="submit" value="create user"></input>
                    <div className="error" id="error">{this.state.error}</div>
                    </div>
                </form>
            </div>
        );
    };
};

export default UserCreate;