import React from 'react';
import User from '../controllers/user';
import Error from './Error';

const _user = new User();

class UserUnlockAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: window.localStorage.getItem("user"),
            sq1: "",
            sq2: "",
            sa1: "",
            sa2: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    async componentDidMount() {
		let result = await _user.getUserQuestions(this.state);
        if (result.valid) {
            this.setState({
                isAuthenticated: true,
				sq1: result["sq1"],
                sq2: result["sq2"],
            });
        } else {
            this.setState({authError: <Error type="unable to get current user details" />});
        }
	}

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    };

    async handleSubmit(e) {
        e.preventDefault();
        if (this.isStaticValid()) {
            let result = await _user.validateUserAnswersAndTokenize(this.state);
			if (result.valid) {
                window.localStorage.setItem("token", result.token);
                window.location = `/passwordUpdate`;
			} else {
				this.setState({"error": result.error});
			}
        }
    };

    isStaticValid() {
        var errors = [];
        (this.state.sa1.trim() === "") && errors.push("security question answer 1 should not be empty");
        (this.state.sa2.trim() === "") && errors.push("security question answer 2 should not be empty");
        this.setState({"error": errors.join(" | ")});
        return errors.length === 0;
    }

    render() {
        if (this.state.authError) { return ( this.state.authError ) }
        if (this.state.isAuthenticated) {
            return (
                <div id="create_user" className="generic-form">
                    <form onSubmit={this.handleSubmit}>
                        <h1 className="title">Password reset</h1>
                        <div id="security-questions">
                            <div className="field dist"><p className='lineHeight200'>security question 1:<br /><span className='important-text'>{ this.state.sq1 }</span></p></div>
                            <div className="field dist"><label>answer: </label><input name="sa1" type="text" onChange={this.handleChange}></input></div>
                            <div className="field dist section-header"><p className='lineHeight200'>security question 2:<br /><span className='important-text'>{ this.state.sq2 }</span></p></div>
                            <div className="field dist"><label>answer: </label><input name="sa2" type="text" onChange={this.handleChange}></input></div>
                        </div>
                        <div className="submit-input section-header"><input type="submit" value="submit"></input></div>
                        <div id="error" className="error">{this.state.error}</div>
                    </form>
                </div>
            );
        }
        return ( <div className="pendingElem"></div> );
    };
};

export default UserUnlockAccount;