import React from 'react';

class LatestCollectionsWidget extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getTimeDiff(time) {
        let diff = Math.floor((Date.now()-time)/1000);
        if (!diff) { return '0s'; }
        if (diff <= 60) {
            return diff.toString() + "s";
        } else if (diff <= 3600) {
            return Math.floor(diff/60).toString() + "m";
        } else if (diff <= 86400) {
            return Math.floor(diff/3600).toString() + "h";
        } else {
            return Math.floor(diff/86400).toString() + "d";
        }
    }

	async componentDidMount() {
        this.setState({
			load: true
        });
	}

	render() {
		if (this.state.load) {
			return (
				<div className="widget widget2">
					<span>Most recently updated items</span>
					<table>
						<thead><tr className="widget-header"><td>name</td><td>description</td><td>user</td><td>updated</td></tr></thead>
						<tbody>
							{this.props.dataObj.data.map((item) => <tr className="widgetWithSrc" onClick={() => {window.location = "viewCollection/"+item.user+"/"+item.name.replace(/ /g, "_")}}><td>{item.name}</td><td>{item.description}</td><td class="user-col">{item.user}</td><td class="updated-col">{this.getTimeDiff(item.updated)}</td></tr> )}
						</tbody>
					</table>
				</div>
			);
		}
		return ( <div className="pendingElem"></div> );
    };
};

export default LatestCollectionsWidget;