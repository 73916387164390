import React from 'react';

class Faq extends React.Component {
	render() {
		return (
			<div class="staticPage">
				<h1>Frequently asked questions:</h1>
                <div className="faqItem">
                    <p className="faqQuestion">I want to share my profile with others. How can I do that?</p>
                    <p className="faqAnswer">The url for user profiles is "https://haihai.link/viewUser/USERNAME" where "USERNAME" is your username.<br />You can also get the url by signing into the website and going to "View Profile" and copying the URL in the browser.<br /><br />example: my haihai.link profile is: <a href='http://haihai.link/viewUser/senaonp'>https://haihai.link/viewUser/senaonp</a></p>
                </div>
                <div className="faqItem">
                    <p className="faqQuestion">I want to share my URL collection with others. How can I do that?</p>
                    <p className="faqAnswer">Go to the URL collection you would like to share; it should have a URL similar to "https://haihai.link/viewCollection/USERNAME/URL_COLLECTION_NAME" where "USERNAME" is your username and "URL_COLLECTION_NAME" is the collection name (with spaces replaced by underscores). This URL can be shared with others as long the collection is set to public.<br /><br />example: my websites URL collection is: <a href='https://haihai.link/viewCollection/senaonp/my_websites'>https://haihai.link/viewCollection/senaonp/my_websites</a></p>
                </div>
                <div className="faqItem">
                    <p className="faqQuestion">I would like to search for URL collections, is there a way to do so?</p>
                    <p className="faqAnswer">URL search is currently available through the searchbar. Items available under the search feature are currently moderated by the administrator so any public collections are added based on my availability to maintain entries though i will try to review new public URL collections.</p>
                </div>
                <div className="faqItem">
                    <p className="faqQuestion">Are all user profiles public?</p>
                    <p className="faqAnswer">Yes, user profiles are public; however, URL collections can be set to either public or private</p>
                </div>
                <div className="faqItem">
                    <p className="faqQuestion">Is there an API I can use to programmatically access this webapp?</p>
                    <p className="faqAnswer">Yes, a Python API to access data on haihai.link is available here: <a target="_blank" rel="noreferrer" href="https://github.com/senaonp/haihai.link-API">https://github.com/senaonp/haihai.link-API</a></p>
                </div>
                <div className="faqItem">
                    <p className="faqQuestion">I am getting an error that has contains the words "API error", what should I do?</p>
                    <p className="faqAnswer">If an error contains "API error", feel free to send a report the error in an email using the <a href="/info/contact/">contact page</a> along with details on what action was attempted as well as any inputs.</p>
                </div>
                <br />
                <hr />
                <h2>Have a question on the website?</h2>
                <p>Feel free to send an email to: <a href="mailto:dev@greentea.moe">dev@greentea.moe</a></p>
			</div>
		);
    };
};

export default Faq;