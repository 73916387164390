import React from 'react';
import User from '../controllers/user';
import Error from './Error';

const _user = new User();

class UserPasswordUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: window.localStorage.getItem('user'),
			token: window.localStorage.getItem('token'),
            password: "",
            passwordVerify: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    async componentDidMount() {
		let result = await _user.getIsSignedIn(this.state);
		if (!result.valid) { 
            this.setState({authError: <Error type="unable to view this endpoint" />});
            return;
        }
        this.setState({ isAuthenticated: true });
	}

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    };

    async handleSubmit(e) {
        e.preventDefault();
        if (this.isStaticValid()) {
            let result = await _user.updateUserPassword(this.state);
			if (result.valid) {
                window.location = `/flash/updatedPassword`;
			} else {
				this.setState({"error": result.error});
			}
        }
    };

    isStaticValid() {
        var errors = [];
        var regex = /^[a-zA-Z0-9]+$/i;
        (this.state.password === "") && errors.push("new password should not be empty");
        (!this.state.password.match(regex)) && errors.push("password should only contain alphanumeric characters");
        (this.state.password !== this.state.passwordVerify) && errors.push("new password is not verified; please ensure that the password fields match");
        this.setState({"error": errors.join(" | ")});
        return errors.length === 0;
    };
    
    render() {
        if (this.state.authError) { return ( this.state.authError ) }
        if (this.state.isAuthenticated) {
            return (
                <div id="update_user" className="generic-form">
                    <form onSubmit={this.handleSubmit}>
                        <h1 className="title">Password update</h1>
                        <div id="security-questions">
                            <div className="field dist"><label>new password: </label><input name="password" type="password" onChange={this.handleChange}></input></div>
                            <div className="field dist"><label>confirm new password: </label><input name="passwordVerify" type="password" onChange={this.handleChange}></input></div>
                        </div>
                        <div className="submit-input section-header">
                            <input type="submit" value="submit"></input>    
                        </div>
                        <div className="error" id="error">{this.state.error}</div>
                    </form>
                </div>
            );
        }
        return ( <div className="pendingElem"></div> );
    };
};

export default UserPasswordUpdate;