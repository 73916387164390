const host = process.env.REACT_APP_EXPRESS_HOST;
const port = process.env.REACT_APP_EXPRESS_PORT;
const protocol = process.env.REACT_APP_EXPRESS_PROTOCOL;
const endpoint = `${protocol}://${host}:${port}`;

class Collection {
    createCollection(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/createCollection`, {
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    getCollection(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/getCollection`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    updateCollection(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/updateCollection`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }

    deleteCollection(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/deleteCollection`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            }).then(res => { resolve(res.json()); });
        });
    }
}

export default Collection;