import React from 'react';

class SupportHaihai extends React.Component {
	render() {
		return (
			<div class="staticPage">
				support haihai
			</div>
		);
    };
};

export default SupportHaihai;