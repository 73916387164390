import React from 'react';
import User from '../controllers/user';

const _user = new User();

class Signin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            password: "",
            action: "add"
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    };

    async handleSubmit(e) {
        e.preventDefault();
        if (this.isStaticValid()) {
                let res = await _user.updateUserToken(this.state);
                if (res.valid) {
                    window.localStorage.setItem("user", this.state.name);
                    window.localStorage.setItem("token", res.token);
                    window.location = `/viewCollections`;
                } else {
                    this.setState({"error": "an invalid username or an incorrect password was entered"});
                }
        }
    };

    isStaticValid() {
        var errors = [];
        (this.state.name.length === 0) && errors.push("username should not be empty");
        (this.state.password.length === 0) && errors.push("password should not be empty");
        this.setState({"error": errors.join(" | ")});
        return errors.length === 0;
    }
	
	render() {
        return (
			<div id="create_user" className="generic-form">
				<h1 className="title">Sign into account</h1>
				<form onSubmit={this.handleSubmit}>
					<div className="field dist"><label>username: </label><input name="name" type="text" onChange={this.handleChange}></input></div>
					<div className="field dist"><label>password: </label><input name="password" type="password" onChange={this.handleChange}></input></div>
					<div className="submit-input dist"><input type="submit" value="submit"></input></div>
                    <div id="error" className="error">{this.state.error}</div>
				</form>

				<div className="section-append">
					<p className="notes dist">[ <a href="/newUser">Don't have an account? Create a new one here</a> ]</p>
					<p className="notes dist">[ <a href="/userVerify">Reset password</a> ]</p>
				</div>
			</div>
		);
    };
};

export default Signin;