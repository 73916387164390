import React from 'react';
import User from '../controllers/user';
import CollectionElem from './mini/CollectionElem';
import Error from './Error';

const _user = new User();

class CollectionsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collections: [],
            hasCollections: false,
            isAuthenticated: false,
            name: window.localStorage.getItem("user"),
            token: window.localStorage.getItem("token"),
        }
	}

    async componentDidMount() {
        let result = await _user.getUser(this.state);
        if (result.valid) {
            this.setState({
                isAuthenticated: true, 
                collections: result.user["collections"],
                hasCollections: result.user["collections"] && result.user["collections"].length > 0,
            });
        } else {
            this.setState({authError: <Error type="unable to view collections at this endpoint" />});
        }
	}

    withCollections(state) {
        return (
            <div>
                <h1>Managed URL collections</h1>
                <a href="/newCollection">create a new URL collection</a>
                <table id="managedCollections" className="some-margin-vertical width-full">
                    { state.collections.map((collection) => <CollectionElem name={collection.name} description={collection.description} public={collection.public} username={this.state.name} />) }
                </table>
            </div>
        )
    }

    withoutCollections() {
        return (
            <div style={{padding:"50px 0px 100px 0px"}}>
                <p>it looks like there are no URL collections at the moment</p>
                <a href="/newCollection">create a new collection for your URLs here</a>
            </div>
        )
    }

    render() {
        if (this.state.authError) { return ( this.state.authError ) }
        if (this.state.isAuthenticated) {
            return (
                <div id="view_collection" className="generic-form">
                    { this.state.hasCollections ? this.withCollections(this.state) : this.withoutCollections() }
                </div>
            );
        }
        return ( <div className="pendingElem"></div> );
    };
};

export default CollectionsView;