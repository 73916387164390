import React from 'react';
import User from '../controllers/user';
import Error from './Error';

const _user = new User();

class UserUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sq1: "",
            sa1: "",
            sq2: "",
            sa2: "",
            name: window.localStorage.getItem('user'),
			token: window.localStorage.getItem('token'),
            description: "",
            status: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    async componentDidMount() {
        let result = await _user.getUser(this.state);
        if (result.valid) {
            let status = result.user["status"] ? result.user["status"] : "";
            this.setState({
                isAuthenticated: result.valid,
                description: result.user["description"],
                status: status,
                sq1: result.user["sq1"],
                sa1: result.user["sa1"],
                sq2: result.user["sq2"],
                sa2: result.user["sa2"],
            });
        } else {
            this.setState({authError: <Error type="unable to get current user details" />});
        }
	}

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    async handleSubmit(e) {
        e.preventDefault();
        if (this.isStaticValid()) {
            let result = await _user.updateUser(this.state);
            if (result.valid) {
                window.location = `/flash/updatedUser`;
			} else {
				this.setState({"error": result.error});
			}
        }
    }

    isStaticValid() {
        var errors = [];
        (!this.isSecurityQuestionsValid()) && errors.push("if setting up security questions; please fill out all the security question and answer fields. answers should only contain alphanumeric characters");
        !(this.state.description.length < 250) && errors.push("profile description should be less than 250 characters");
        !(this.state.status.length < 100) && errors.push("profile status should be less than 100 characters");
        this.setState({"error": errors.join(" | ")});
        return errors.length === 0;
    }

    isSecurityQuestionsValid() {
        var regex = /^[a-zA-Z0-9]+$/i;
        if (this.state.sq1.length > 0 || 
            this.state.sq2.length > 0 || 
            this.state.sa1.length > 0 || 
            this.state.sa2.length > 0) {
                if (this.state.sq1.trim().length === 0 || 
                    this.state.sq2.trim().length === 0 || 
                    this.state.sa1.trim().length === 0 || 
                    this.state.sa2.trim().length === 0 ||
                    !this.state.sa1.match(regex) ||
                    !this.state.sa2.match(regex)) {
                        return false;
                }
        }
        return true;
    }

    render() {
        if (this.state.authError) { return ( this.state.error ) }
        if (this.state.isAuthenticated) {
            return (
                <div id="update_user" className="generic-form">
                    <h1>Manage Account - {this.state.name}</h1>
                    <form onSubmit={this.handleSubmit}>
                        <div className="field dist"><a href={`/passwordUpdate`}>click here to update password</a></div>
                        <div className="field dist"><label>profile description (optional): </label><textarea name="description" value={this.state.description} onChange={this.handleChange}></textarea></div>
                        <div className="field dist"><label>profile status (optional): </label><input name="status" value={this.state.status} onChange={this.handleChange}></input></div>

                        <div id="security-questions">
                            <small className="form-text large-dist">setting up security questions is only required if you'd like to setup password reset; <br/><br/><span className="important-text">forgotten passwords can only be reset using security questions</span></small>
                            <div className="field dist"><label>security question 1: </label><input name="sq1" type="text" value={this.state["sq1"]} onChange={this.handleChange}></input></div>
                            <div className="field dist"><label>answer to security question 1: </label><input name="sa1" type="text" value={this.state["sa1"]} onChange={this.handleChange}></input></div>
                            <div className="field dist section-header"><label>security question 2: </label><input name="sq2" type="text" value={this.state["sq2"]} onChange={this.handleChange}></input></div>
                            <div className="field dist"><label>answer to security question 2: </label><input name="sa2" type="text" value={this.state["sa2"]} onChange={this.handleChange}></input></div>
                        </div>
                        <div className="submit-input section-header"><input type="submit" value="update user"></input></div>
                        <div className="error" id="error">{this.state.error}</div>
                    </form>
                    <div className="section-append"><a href="/deleteAccount">Delete Account</a></div>
                </div>
            );
        }
        return ( <div className="pendingElem"></div> );
    };
};

export default UserUpdate;