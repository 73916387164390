import React from 'react';
import User from '../controllers/user';

const _user = new User();

class Navbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: window.localStorage.getItem('user'),
			token: window.localStorage.getItem('token'),
			isSignedIn: false,
			search: "",
		}
		this.handleChange = this.handleChange.bind(this);
	}

	async componentDidMount() {
		let s = await _user.getIsSignedIn(this.state);
		this.setState({isSignedIn: s.valid});
	}

	handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

	signedInNav = <div>
		<li className="signedin navborder navItem" id="sign-out" onClick={this.signOut}>Sign Out</li>
		<li className="signedin navborder navItem" id="user-update" onClick={() => this.navi(`/updateUser`)}>Manage Account</li>
		<li className="signedin navborder navItem" id="user-update" onClick={() => this.navi(`/viewUser/${window.localStorage.getItem("user")}`)}>View Profile</li>
		<li className="signedin navborder navItem" id="collections" onClick={() => this.navi(`/viewCollections`)}>URL collections</li></div>;

	signedOutNav = <div>
		<li className="signedout navborder navItem" id="sign-in" onClick={() => this.navi('/signIn')}>Sign In</li>
		<li className="signedout navborder navItem" onClick={() => this.navi('/newUser')}>Create an Account</li>
		<li className="signedout navborder navItem" onClick={() => this.navi('/')}>Dashboard</li></div>;

	navi(path) { window.location = path; }

	async signOut() {
		await _user.updateUserToken({name: window.localStorage.getItem('user'), token: window.localStorage.getItem('token'), action: 'remove'});
		window.localStorage.setItem('user', '');
		window.localStorage.setItem('token', '');
		window.location = '/flash/signedOut';
	}

	search(e) {
		if (e.key === "Enter") { this.navi(`/searchResults/${this.state.search.replace(/ /g, "_")}`); }
	}

	render() {
		const options = this.state.isSignedIn ? this.signedInNav : this.signedOutNav;
		return (
			<div id="navbar">
				<ul id="nav">
					<li id="logo" onClick={() => this.navi('/')}>haihai.link</li>
					{options}
				</ul>
				<div id="searchBar">
					<div id="searchElem">
						<input name="search" type="text" onChange={this.handleChange} onKeyUp={(e) => this.search(e)}></input>
						<button onClick={() => this.navi(`/searchResults/${this.state.search.replace(/ /g, "_")}`)}>search</button>
					</div>
				</div>
			</div>
		);
    };
};

export default Navbar;