import React from 'react';

class CollectionElem extends React.Component {
    render() {
        let limitShow = null;
        if (this.props.hideRes) {
            limitShow = null;
        } else if (this.props.public) {
            limitShow = <td className="side-padding important-text">public</td>;
        } else {
            limitShow = <td className="side-padding">private</td>;
        }

        return (
            <tr className="collectionElem left-content" onClick={() => window.location=`/viewCollection/${this.props.username}/${this.props.name.replace(/ /g, "_")}`}>
                <td className="collectionAttribute vert-padding">{this.props.name}</td>
                <td className="left-space-mini side-padding">{this.props.description}</td> 
                {limitShow}
            </tr>
        );
    };
}

export default CollectionElem;