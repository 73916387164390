import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/shared.css';

async function renderApp() {
	let app = <App />;
	ReactDOM.render(
		app,
		document.getElementById('root')
	);
}
renderApp();