const host = process.env.REACT_APP_EXPRESS_HOST;
const port = process.env.REACT_APP_EXPRESS_PORT;
const protocol = process.env.REACT_APP_EXPRESS_PROTOCOL;
const endpoint = `${protocol}://${host}:${port}`;

class Widget {
    getWidgetDataLatestCollections(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/getWidgetDataLatestCollections`, {
                method: 'GET', 
                headers: { 'Content-Type': 'application/json' }
            }).then(res => { resolve(res.json()); });
        });
    }

    getWidgetDataLatestContributors(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/getWidgetDataLatestContributors`, {
                method: 'GET', 
                headers: { 'Content-Type': 'application/json' }
            }).then(res => { resolve(res.json()); });
        });
    }

    getWidgetDataLatestUpdates(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/getWidgetDataLatestUpdates`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            }).then(res => { resolve(res.json()); });
        });
    }

    getWidgetDataMeta(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/getWidgetDataMeta`, {
                method: 'GET', 
                headers: { 'Content-Type': 'application/json' },
            }).then(res => { resolve(res.json()); });
        });
    }

    getWidgetFeaturedItems(request) {
        return new Promise(resolve => {
            fetch(`${endpoint}/getWidgetDataFeaturedItems`, {
                method: 'GET', 
                headers: { 'Content-Type': 'application/json' },
            }).then(res => { resolve(res.json()); });
        });
    }
}

export default Widget;