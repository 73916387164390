import React from 'react';

class Footer extends React.Component {
	// add this later ----- <span className="item"><a href='/info/supportHaihai/'>support haihai</a></span>
	render() {
		return (
			<div id="footer">
				<span className="item"><a href='/info/about/'>about</a></span>
				<span className="item"><a href='/info/policy/'>website policy</a></span>
				<span className="item"><a href='/info/contact/'>contact</a></span>
				<span className="item"><a href='/info/faq/'>FAQ</a></span>
			</div>
		);
    };
};

export default Footer;