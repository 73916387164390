import React from 'react';
import User from '../controllers/user';

const _user = new User();

class UserUnlockAccountEnter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    };

    async handleSubmit(e) {
        e.preventDefault();
        if (this.isStaticValid()) {
            let result = await _user.getIsUserAndSetupQuestions(this.state);
            if (result.valid) {
                window.localStorage.setItem("user", this.state.name);
                window.location = `/unlockAccount`;
            } else {
                this.setState({"error": result.error});
            }
        }
    };

    isStaticValid() {
        var errors = [];
        (this.state.name === "") && errors.push("username should not be empty");
        this.setState({"error": errors.join(" | ")});
        return errors.length === 0;
    }

    render() {
        return (
            <div id="create_user" className="generic-form">
                <form onSubmit={this.handleSubmit}>
                    <h1 className="title">Password reset</h1>
                    <div id="security-questions">
                        <div className="field dist"><label>username: </label><input name="name" type="text" onChange={this.handleChange}></input></div>
                    </div>
                    <div className="submit-input section-header"><input type="submit" value="submit"></input></div>
                    <div id="error" className="error">{this.state.error}</div>
                </form>
            </div>
        );
    };
};

export default UserUnlockAccountEnter;