import React from 'react';
import Collection from '../controllers/collection';
import User from '../controllers/user';

import Error from './Error';

const _user = new User();
const _collection = new Collection();

class CollectionCreate extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            user: window.localStorage.getItem('user'),
			token: window.localStorage.getItem('token'),
            name: "",
            description: "",
            public: false,
            urls: [{urlName: "", urlDescription: "", url: ""}],
            isAuthenticated: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addURLtop = this.addURLtop.bind(this);
        this.addURLbottom = this.addURLbottom.bind(this);
        this.removeURL = this.removeURL.bind(this);
	};

    async componentDidMount() {
		let result = await _user.getIsSignedIn({name: this.state.user, token: this.state.token});
		if (!result.valid) { 
            this.setState({authError: <Error type="unable to view this endpoint" />});
            return;
        }
        this.setState({ isAuthenticated: true });
	}

    handleChange(e) {
        if (e.target.type === "select-one") {
            if (e.target.value === "yes") { this.setState({[e.target.name]: true}); }
			else { this.setState({[e.target.name]: false}); };
		} else if (["urlName","urlDescription","url"].includes(e.target.name.split("_")[0])) {
            let idx = e.target.name.split("_")[1];
            let obj = this.state.urls[idx];
            let newURLs = [];
            for (var i=0; i<this.state.urls.length; i+=1) {
                if (i.toString()===idx) {
                    obj[e.target.name.split("_")[0]] = e.target.value;
                    newURLs.push(obj);
                } else {
                    newURLs.push(this.state.urls[i]);
                }
            }
            this.setState({urls: newURLs});
        } else {
		    this.setState({[e.target.name]: e.target.value});
        }
    };

    async handleSubmit(e) {
        e.preventDefault();
        if (this.isStaticValid()) {
            let result = await _collection.createCollection(this.state);
            if (result.valid) {
                window.location = `/viewCollection/${this.state.user}/${this.state.name.replace(/ /g, "_")}`;
			} else {
				this.setState({error: result.error});
			}
        }
    };

    isStaticValid() {
        var errors = [];
        (this.state.name.trim() === "") && errors.push("collection name should not be empty");
        (this.state.name.includes("%")) && errors.push("collection name should not contain the '%' character as it is handled as a URI component");
        (this.state.description.trim() === "") && errors.push("collection description should not be empty");
        !(this.state.name.length < 100) && errors.push("name should be less than 100 characters");
        !(this.state.description.length < 250) && errors.push("profile description should be less than 250 characters");
        this.setState({error: errors.join(" | ")});
        return errors.length === 0;
    }

    removeURL(idx) {
        let newURLs = [];
        let urls = this.state.urls;
        for (var i=0; i<urls.length; i+=1) {
            if (i !== idx) { 
                newURLs.push(urls[i]);
            }
        }
        this.setState({urls: newURLs});
    }

    addURLtop() {
        this.setState(prevState => ({
            urls: [{urlName: "", urlDescription: "", url: ""}, ...prevState.urls]
        }));
    }

    addURLbottom() {
        this.setState(prevState => ({
            urls: [...prevState.urls, {urlName: "", urlDescription: "", url: ""}]
        }));
    }

    render() {
        if (this.state.authError) { return ( this.state.authError ) }
        if (this.state.isAuthenticated) {
            return (
                <div id="create_collection">
                    <form className="generic-form" onSubmit={this.handleSubmit}>
                        <h1>Create a new URL collection</h1>
                        <div className="field"><label>Name: </label><input name="name" type="text" onChange={this.handleChange}></input></div>
                        <div className="field"><label>Description: </label><textarea name="description" onChange={this.handleChange}></textarea></div>
                        <div className="dist some-margin-vertical"><label>Make this collection public: </label>
                            <select name="public" className="left-space-mini" onChange={this.handleChange}>
                                <option value="no">no</option>
                                <option value="yes">yes</option>
                            </select></div>

                        <h2 className="form-text section-header">URLs</h2>
                        <small className="form-small">URLs to add to the collection (name, description, and tags are optional)</small>
                        <table className="update-table three-col">
                            <thead><tr><th className="short"></th><th>URL (website link)</th><th>name</th><th>description</th><th></th></tr></thead>
                            <tbody id="url-collection">
                                {this.state.urls.map((url, idx) =>
                                    <tr>
                                        <td className="short"><span>{idx}</span></td>
                                        <td><input name={`url_${idx}`} value={url.url} onChange={this.handleChange}></input></td>
                                        <td><input name={`urlName_${idx}`} value={url.urlName} onChange={this.handleChange}></input></td>
                                        <td><textarea name={`urlDescription_${idx}`} value={url.urlDescription} onChange={this.handleChange}></textarea></td>
                                        <td><button type="button" onClick={() => this.removeURL(idx)}>remove</button></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <button className="form-button some-padding" type="button" onClick={this.addURLtop}>add another URL on top</button>
                        <button className="form-button some-padding left-space" type="button" onClick={this.addURLbottom}>add another URL on bottom</button>

                        <div className="submit-input section-header"><input type="submit" value="submit"></input></div>
                        <div className="error">{this.state.error}</div>
                    </form>
                </div>
            );
        }
        return ( <div className="pendingElem"></div> );
    };
};

export default CollectionCreate;