import React from 'react';
import Collection from '../controllers/collection';
import User from '../controllers/user';
import Error from './Error';

const _collection = new Collection();
const _user = new User();

class CollectionDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			name: window.localStorage.getItem('user'),
			token: window.localStorage.getItem('token'),
			verifyDeleteCheck: false,
        };
		this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

	async componentDidMount() {
		let result = await _user.getIsSignedIn(this.state);
		if (!result.valid) { 
            this.setState({authError: <Error type="unable to view this endpoint" />});
            return;
        }
        this.setState({ isAuthenticated: true });
	}

	handleChange(e) {
		if (e.target.type === "checkbox") {
			this.setState({[e.target.name]: e.target.checked});	
		} else {
			this.setState({[e.target.name]: e.target.value});
		}
	};

    async handleSubmit(e) {
		e.preventDefault();
		if (this.isStaticValid()) {
			let result = await _collection.deleteCollection({user: this.state.name, token: this.state.token, collectionId: this.props.collectionId});
			if (result.valid) {
				window.location = `/flash/deletedCollection`;
			} else {
				this.setState({"error": result.error});
			}
		}
	};

	isStaticValid() {
        var errors = [];
        (this.state.verifyDeleteCheck !== true) && errors.push("error: the checkbox to verify account deletion is not checked");
        this.setState({"error": errors.join(" | ")});
        return errors.length === 0;
    }

	render() {
		if (this.state.authError) { return ( this.state.authError ) }
		let collectionName = "";
		try { collectionName = decodeURIComponent(this.props.collectionId) }
		catch { collectionName = this.props.collectionId }
        if (this.state.isAuthenticated) {
			return (
				<div id="create_user" className="generic-form">
					<h1 className="title">Delete URL collection verification</h1>
					<form onSubmit={this.handleSubmit}>
						<div className="dist"><label>I acknowledge that by submitting this form, all the data in URL collection [{collectionName}] will be deleted<br /><br /><span className="important-text">check the checkbox to acknowledge statement</span>: </label>
							<input name="verifyDeleteCheck" type="checkbox" onChange={this.handleChange}></input>
						</div>
						<div className="submit-input section-header">
							<input type="submit" value="delete"></input>
							<p className="error" id="error">{this.state.error}</p>
						</div>
					</form>
				</div>
			);
		}
		return ( <div className="pendingElem"></div> );
    };
};

export default CollectionDelete;