import React from 'react';

class Contact extends React.Component {
	render() {
		const emailIssueSubject = "haihai.link error reporting".split(" ").join("%20");
		const emailIssueBody = "please provide a description of the issue including steps to replicate the issue%0A________________%0A%0A".split(" ").join("%20");
		const emailFeedbackSubject = "haihai.link feedback".split(" ").join("%20");
		const emailFeedbackBody = "feel free to provide feedback on the website and if there are any features or improvement suggestions%0A________________%0A%0A".split(" ").join("%20");
		return (
			<div class="staticPage">
				<h1>contact</h1>
				<p>my developer email is <a href="mailto:dev@greentea.moe">dev@greentea.moe</a></p><br />
				<p>for frequently referenced topics on emails; i may add them to the <a href="/info/faq">FAQ section</a>, so feel free to check that section as well</p>
				<p>i mainly look at emails related to either issues or feedback; i added email templates below for those purposes as well:</p>
				<ul>
					<li><a href={`mailto:dev@greentea.moe?subject=${emailIssueSubject}&body=${emailIssueBody}`}>send an email to report errors and issues on the website</a></li>
					<li><a href={`mailto:dev@greentea.moe?subject=${emailFeedbackSubject}&body=${emailFeedbackBody}`}>send an email on feedback and suggestions on the website</a></li>
				</ul>
				<br />
				<p>please note: i do not respond to emails related to user passwords (details on password management are in the <a href="/info/policy">website policy</a>)</p>
				<p>sending an email does not guarantee a reply; i will try based on availability and prioritization to address issues and consider feature improvements, thanks for your understanding.</p>
			</div>
		);
    };
};

export default Contact;