import React from 'react';
import User from '../controllers/user';
import Error from './Error';

const _user = new User();

class UserDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			name: window.localStorage.getItem('user'),
			token: window.localStorage.getItem('token'),
			verifyDeleteMessage: "",
			verifyDeleteCheck: false,
        };
		this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

	async componentDidMount() {
		let result = await _user.getIsSignedIn(this.state);
		if (!result.valid) { 
            this.setState({authError: <Error type="unable to view this endpoint" />});
            return;
        }
        this.setState({ isAuthenticated: true });
	}

	handleChange(e) {
		if (e.target.type === "checkbox") {
			this.setState({[e.target.name]: e.target.checked});	
		} else {
			this.setState({[e.target.name]: e.target.value});
		}
	};

    async handleSubmit(e) {
		e.preventDefault();
		if (this.isStaticValid()) {
			let result = await _user.deleteUser({name: this.state.name, token: this.state.token, collectionId: this.props.collectionId});
			if (result.valid) {
				window.location = `/flash/deletedAccount`;
			} else {
				this.setState({"error": result.error});
			}
		}
	};

	isStaticValid() {
        var errors = [];
        (this.state.verifyDeleteCheck !== true) && errors.push("error: the checkbox to verify account deletion is not checked");
		(this.state.verifyDeleteMessage !== "delete") && errors.push("error: the delete account text field does not have 'delete'");
        this.setState({"error": errors.join(" | ")});
        return errors.length === 0;
    }

	render() {
		if (this.state.authError) { return ( this.state.authError ) }
        if (this.state.isAuthenticated) {
			return (
				<div id="create_user" className="generic-form">
					<h1 className="title">Delete account verification</h1>
					<p className="lineHeight200">note: deleting your user account will remove information related to the account this information includes:<br />[ <span className="important-text">the account itself</span> ]<br />[ <span className="important-text">data (e.g. URL collection) managed by the account</span> ]<br/><br/>
						If you would like to proceed with deleting the account please fill out the fields below<br/></p><hr/>
					<form onSubmit={this.handleSubmit}>
						<div className="dist"><label>I acknowledge that by submitting this form my account will be deleted<br /><br /><span className="important-text">check the checkbox to acknowledge statement</span>: </label>
							<input name="verifyDeleteCheck" type="checkbox" onChange={this.handleChange}></input>
						</div>
						<hr />
						<div className="dist"><label>Please type '<span className="important-text">delete</span>' in this field to verify intent to delete account: </label>
							<input name="verifyDeleteMessage" type="text" onChange={this.handleChange}></input>
						</div>
						<hr />
						<div className="submit-input section-header">
							<p>Once the two fields are filled out, the Submit button can be used to delete the account</p>
							<input type="submit" value="delete"></input>
							<p className="error" id="error">{this.state.error}</p>
						</div>
					</form>
				</div>
			);
		}
		return ( <div className="pendingElem"></div> );
    };
};

export default UserDelete;